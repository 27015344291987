import React from "react";
import MusicBarAnimation from "./MusicBarAnimation";
import EmptyDivider from "./EmptyDivider";
import ReactHowler from 'react-howler'
import SocialMediaIconsContainer from "./SocialMediaIconsContainer";
import Objects from "../utils/Objects";
import {WebLinkCollection} from "../utils/WebLinkCollection";

const PLAYER_BUTTON_CLASS_NAMES = "btn btn-sm btn-outline-light ps-4 pe-4 m-2 shadow";

export default function ShoutcastStreamPlayer({eventSchedule = null}) {

    const [audioStreamAvailable, setAudioStreamAvailable] = React.useState(true);
    const [playing, setPlaying] = React.useState(false);
    const [muted, setMuted] = React.useState(false);

    const updateAudioStreamAvailable = function (available) {
        setAudioStreamAvailable(available);
    }

    const toggleAudioStreamPlaying = function() {
        setPlaying(!playing);
    }

    const toggleAudioStreamMuted = function() {
        setMuted(!muted);
    }

    return (
        <React.Fragment>
            <div className="container-fluid inline-shoutcast-player shadow-lg"
                 style={{width: "auto", maxWidth: "699px"}}>

                <div className="container">
                    <div className="row flex-grow-1 text-white">
                        <div className="col text-center">

                            {audioStreamAvailable && (
                                <React.Fragment>
                                    <EmptyDivider />

                                    <MusicBarAnimation/>

                                    <span className="text-white">
                                        <i className="bi bi-activity me-2"/>
                                        <strong className="badge bg-light text-dark me-2">NOW STREAMING LIVE</strong>
                                    </span>

                                    <EmptyDivider />

                                    {Objects.isNotNull(eventSchedule) && (
                                        <React.Fragment>
                                            <span className="text-bold">{eventSchedule.title}</span>
                                            <br/>
                                            <span className="font-size-90-pct">
                                                {eventSchedule.performer} - {eventSchedule.venueName}
                                            </span>
                                        </React.Fragment>
                                    )}

                                    {Objects.isNull(eventSchedule) && (
                                        <React.Fragment>
                                            <span className="text-bold">DJ TSEPO IN THE MIX ~ FREESTYLING AFROHOUSE</span>
                                            <br/>
                                            <span className="font-size-90-pct">DJ Tsepo Mngomezulu</span>
                                        </React.Fragment>
                                    )}

                                </React.Fragment>
                            )}

                            {!audioStreamAvailable && (
                                <React.Fragment>
                                    <EmptyDivider/>

                                    <span className="text-white">
                                        <i className="bi bi-heartbreak-fill me-2"/>
                                        <strong className="badge bg-light text-dark me-2">STREAM NOT PLAYING</strong>
                                    </span>

                                    <br />

                                    <small className="text-white font-size-90-pct">
                                        The audio stream is available.
                                        Press <span className="text-bold">PLAY</span> to begin streaming.
                                    </small>
                                </React.Fragment>
                            )}
                        </div>
                    </div>

                    <EmptyDivider />

                    <div className="row flex-grow-1 text-white justify-content-center">
                        <div className="col p-2 text-center">
                            <button className={PLAYER_BUTTON_CLASS_NAMES} type="button" title="Play / Pause"
                                    onClick={toggleAudioStreamPlaying}>

                                {playing && (
                                    <React.Fragment>
                                        <i className="bi bi-pause me-2"/>
                                        <span className="text-bold">PAUSE</span>
                                    </React.Fragment>
                                )}

                                {!playing && (
                                    <React.Fragment>
                                        <i className="bi bi-play me-2"/>
                                        <span className="text-bold">PLAY</span>
                                    </React.Fragment>
                                )}
                            </button>

                            {audioStreamAvailable && (
                                <React.Fragment>
                                    <button className={PLAYER_BUTTON_CLASS_NAMES} type="button" title="Mute / Unmute"
                                            onClick={toggleAudioStreamMuted} disabled={!playing}>

                                        {muted && (
                                            <React.Fragment>
                                                <i className="bi bi-volume-down me-2"/>
                                                <span className="text-bold">UNMUTE</span>
                                            </React.Fragment>
                                        )}

                                        {!muted && (
                                            <React.Fragment>
                                                <i className="bi bi-volume-mute me-2"/>
                                                <span className="text-bold">MUTE</span>
                                            </React.Fragment>
                                        )}
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>

                    <ReactHowler src={WebLinkCollection.shoutcastStream}
                                 preload={true} html5={true} volume={1.0}
                                 playing={playing} mute={muted}
                                 onLoad={() => updateAudioStreamAvailable(true)}
                                 onLoadError={() => updateAudioStreamAvailable(false)}
                                 onEnded={() => updateAudioStreamAvailable(false)}
                                 onPlayError={() => updateAudioStreamAvailable(false)}/>

                    <hr />

                    <div className="container justify-content-center">
                        <SocialMediaIconsContainer />
                    </div>
                </div>

                <EmptyDivider />

            </div>
        </React.Fragment>
    )
}