import React from "react";
import EmptyDivider from "../components/EmptyDivider";
import PerformanceEventsPresenter from "../components/PerformanceEventsPresenter";
import ShoutcastStreamPlayer from "../components/ShoutcastStreamPlayer";
import ApiFetch from "../utils/ApiFetch";
import Objects from "../utils/Objects";
import {WebLinkCollection} from "../utils/WebLinkCollection";
import EmbeddedYoutubeVideos from "../components/EmbeddedYoutubeVideos";
import SocialMediaIconsContainer from "../components/SocialMediaIconsContainer";
import Arrays from "../utils/Arrays";

const NO_VIDEO_PLAYBACK_CLASS_NAMES = "container-fluid text-white landing-view-header-background-v2 img-background fill-viewport-height shadow-lg";
const VIDEO_PLAYBACK_CLASS_NAMES = "video-background-container purple-background text-white shadow-lg";

const LIVE_STREAM_PLAYBACK_VIDEO = "/video/4440816-hd_1280_720_50fps.mp4";

const SHOUTCAST_SERVER_PROBE_RELATIVE_URL = "/shoutcast-server/probe";
const PERFORMANCE_EVENT_SCHEDULES_ON_SCHEDULE_RELATIVE_URL = "/performance-event-schedules/on-schedule";

function LandingHeaderContainer() {
    let interval = null;

    const [eventSchedule, setEventSchedule] = React.useState(null);
    const [enableShoutcastStreamPlayer, setEnableShoutcastStreamPlayer] = React.useState(false);

    const getHeaderClassNames = function (toggle) {
       return (toggle) ? VIDEO_PLAYBACK_CLASS_NAMES : NO_VIDEO_PLAYBACK_CLASS_NAMES;
    }

    const probeShoutcastServerStream = function () {
        if (!enableShoutcastStreamPlayer) {
            new ApiFetch()
                .get(SHOUTCAST_SERVER_PROBE_RELATIVE_URL)
                .then((response) => {
                    const responseBody = ApiFetch.getResponseBody(response);
                    const available = Objects.isNotNull(responseBody) && responseBody.data;

                    setEnableShoutcastStreamPlayer(available);
                })
                .catch(() => {
                    setEnableShoutcastStreamPlayer(false);
                });
        }
    }

    const fetchEventSchedulesOnSchedule = function () {
       new ApiFetch()
           .get(PERFORMANCE_EVENT_SCHEDULES_ON_SCHEDULE_RELATIVE_URL)
           .then((response) => {
                const responseBody = ApiFetch.getResponseBody(response);

                if (responseBody && responseBody.data) {
                    const eventSchedules = responseBody.data;
                    setEventSchedule(Arrays.hasItems(eventSchedules) ? eventSchedules[0] : null);
                } else {
                    setEventSchedule(null);
                }
            });
    }

    const initEffect = function () {
        fetchEventSchedulesOnSchedule();
        probeShoutcastServerStream();

        if (Objects.isNull(interval)) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            interval = setInterval(() => {
                fetchEventSchedulesOnSchedule();
                probeShoutcastServerStream();
            }, 30000);
        }
    }

    React.useEffect(initEffect, []);

    return (
        <React.Fragment>
            <header className={getHeaderClassNames(enableShoutcastStreamPlayer)}>
                {enableShoutcastStreamPlayer && (
                    <React.Fragment>
                        <video autoPlay={true} muted={true} loop={true} playsInline={true}>
                            <source src={LIVE_STREAM_PLAYBACK_VIDEO} type="video/mp4"/>
                            Your browser does not support the video player.
                        </video>
                    </React.Fragment>
                )}

                <div className={enableShoutcastStreamPlayer ? "content" : "content absolutely-positioned-to-bottom"}>
                    <EmptyDivider degree={1}/>

                    <LandingHeaderLegend showHeadingAndTagLine={!enableShoutcastStreamPlayer} />

                    {enableShoutcastStreamPlayer && (
                        <React.Fragment>
                            <EmptyDivider degree={1} />
                            <ShoutcastStreamPlayer eventSchedule={eventSchedule} />
                        </React.Fragment>
                    )}

                    {!enableShoutcastStreamPlayer && (
                        <React.Fragment>
                            <div className="container text-center justify-content-center">
                                <SocialMediaIconsContainer/>

                                <EmptyDivider degree={1} />

                                <a href={WebLinkCollection.mixCloudPage} className="btn btn-outline-light shadow-lg m-2"
                                   title="Listen on Mixcloud" role="button" target="_blank" rel="noreferrer">
                                    <span><i className="bi bi-headphones me-2"/>Listen on Mixcloud</span>
                                </a>

                                <EmptyDivider />
                            </div>
                        </React.Fragment>
                    )}

                    <EmptyDivider degree={1}/>

                    {!enableShoutcastStreamPlayer && (
                        <React.Fragment>
                            <div className="w-100 d-block p-2 pe-4 text-align-right">
                                <small className="text-uppercase">
                                    <i className="bi bi-heart-fill me-2"></i>
                                    <span>Photo taken by </span>
                                    <a href="https://www.flickr.com/photos/201739952@N03/" target="_blank"
                                       rel="noreferrer" title="Breuddwyd on Flickr" className="text-white">Breuddwyd</a>
                                </small>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </header>
        </React.Fragment>
    )
}

function LandingHeaderLegend({showHeadingAndTagLine = true}) {
    return (
        <React.Fragment>
            {showHeadingAndTagLine && (
                <React.Fragment>
                    <div className="container text-center pe-4 ps-4">
                        <h1 className="display-1">House music heals, inspires and uplifts the soul.</h1>
                        <p>Prepare to transform and awaken your spirit.</p>
                    </div>
                </React.Fragment>
            )}

            <EmptyDivider degree={1}/>

            <div className="container text-center">
                <p>
                    <span className="badge purple-background m-1 jiggle-animation">GOOD MUSIC</span>
                    <span className="badge purple-background m-1 jiggle-animation">GOOD VIBES</span>
                    <span className="badge purple-background m-1 jiggle-animation">GOOD PEOPLE</span>
                </p>
            </div>
        </React.Fragment>
    )
}

export default function LandingView() {

    return (
        <React.Fragment>
            <LandingHeaderContainer />
            <PerformanceEventsPresenter />
            <EmbeddedYoutubeVideos />
        </React.Fragment>
    )
}