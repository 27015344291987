import React from "react";
import Strings from "../utils/Strings";

const collapseNavigationBar = function () {
    const navbar = document.getElementById("navbarSupportedContent");

    if (navbar) {
        navbar.classList.remove("show");
    }
}

function NavigationItem({title, href, iconStyle}) {
    const iconClassNames = Strings.format("bi bi-{0} me-2", iconStyle);

    return (
        <React.Fragment>
            <li className="nav-item ms-2 me-2 font-size-80-pct text-uppercase" onClick={() => collapseNavigationBar()}>
                <a className="nav-link text-white text-bold" title={title} href={href}>
                    <i className={iconClassNames} />
                    <span>{title}</span>
                </a>
            </li>
        </React.Fragment>
    )
}

export default function TopFixedNavigationBar() {
    return (
        <React.Fragment>
            <nav
                className="navbar navbar-expand-lg darker-purple-background text-white fixed-top shadow-lg">
                <div className="container-fluid ms-2">

                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="navbar-brand text-white" href="#">
                        <span className="text-bold">TSEPO MNGOMEZULU</span>
                    </a>

                    <button id="navbarTogglerButton" className="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon text-white"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <NavigationItem title="Home" href="#" iconStyle="house"/>
                            <NavigationItem title="Event Schedule" href="#performance-event-schedules" iconStyle="calendar-event"/>
                            <NavigationItem title="Featured" href="#featured-youtube-videos" iconStyle="youtube"/>
                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}