import React from "react";
import EmptyDivider from "../components/EmptyDivider";

export default function PrivacyPolicyView() {
    return (
        <React.Fragment>
            <EmptyDivider degree={3} />

            <div className="container pe-2 ps-2">
                <h1>Privacy Policy</h1>
                <p>Last updated on: <span className="text-bold">19 November 2024</span></p>

                <hr />

                <p>Under construction</p>
            </div>

            <EmptyDivider degree={2} />
        </React.Fragment>
    )
}